@import '../theme.scss';

.account_settings {
    background-color: $bg-second-color;
    border-radius: 10px;
    overflow: hidden;
}
.account_settings--title {
    display: flex;
    justify-content: space-between;
    padding: 0.6rem 1rem;
    background-color: $bg-primary-color;
    cursor: pointer;
}
.account_settings--infor {
    font-weight: 700;
    color: $text-color;
}
.account_settings--content {
    padding: 5%;
}
.account_settings--update {
    color: $pink-dark;
}
.account_settings--field {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    &:last-child {
        margin-top: 2rem;
        justify-content: flex-end;
    }
    span {
        width: 150px;
        font-size: 1rem;
        font-weight: 700;
        color: $text-color;
    }
    .input-field {
        width: 60%;
    }
}