$bg-content-color : #eaebee; 
$btn-pink-color : #e72670;
$text-pink-color : #e84b87;
$text-active: #221f20;
$text-dark: #394142;
$bg-input: #dddfe4;
.store-config{
    display: flex;
    .menu-tab{
        width: 23%;
        display: flex;
        flex-direction: column;
        padding-right: 1%;
        padding-left: 0%;
    }
    
    .content-tab{
        width: 77%;
        border-left: 1px solid rgb(49, 49, 49);
    }
}

@media screen and (max-width: 768px) {
    .store-config{
        flex-direction: column;
        .menu-tab{
            flex-direction: row;
            width: 100%;
            margin-bottom: 2%;
            justify-content: space-between;
            margin-bottom: 10%;
        }
        .content-tab{
            width: 100%;
            border-left: none;
        }
    }
}