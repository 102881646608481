.payment {
    padding-left: 4rem;
}
.payment_field {
    display: flex;
    align-items: center;
    padding: 0.7rem 0;
}
.payment_title {
    font-size: 1.2rem;
    font-weight: 700;
    width: 250px;
}
.payment_method {
    display: flex;
    margin-right: 1rem;
    cursor: pointer;
}
.payment_iconBox {
    position: relative;
    svg {
        position: absolute;
        top: 8px;
        left: 10px;
        font-size: 24px;
    }
    .payment_input {
        width: 100%;
        .input {
            padding-left: 50px;
        }
    }
}
.payment_input {
    width: 40%;
    height: 40px;
}
.input {
    height: 40px;
    border-radius: 5px;
}