@import './theme.scss';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
}
p,h1,h2,h3,h4,h5,h6 {
    margin: 0;
    padding: 0;
}
a {
    text-decoration: none;
}
ul {
    margin: 0;
}
button, input {
    border: none;
    outline: none;
}

// style header
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 5% 20px 10%;
}
.header_logo {
    width: 250px;
    img {
        width: 100%;
    }
}
.header_sidebar {
    position: relative;
    height: 80px;
    padding-left: 50px;
    display: flex;
    align-items: center;
    border-left: 2px solid $dark-color;
    .header_hover {
        height: 100%;
        display: flex;
        align-items: center;
        .header_userDisplay {
            cursor: pointer;
            width: 50px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            background-color: $bg-second-color;
            font-weight: 700;
            color: $pink-dark;
            border-radius: 50%;
        }
        .header_options {
            position: absolute;
            display: none;
            right: 0;
            top: 100%;
            z-index: 99;
            background-color: #eaecef;
            border-radius: 10px;
            overflow: hidden;
            padding: 1rem 0;
            .header_option {
                cursor: pointer;
                white-space: nowrap;
                padding: 10px;
                &:hover {
                    background-color: $bg-three-color;
                }
            }
        }
        &:hover .header_options {
            display: block;
        }
    }
    .languageSelect {
        width: auto;
    }
    .languageSelected {
        border-bottom: none;
    }
}
// style content
.content {
    padding: 2rem 5%;
}

// responsive for mobile
@media screen and (max-width: 768px) {
    .header {
        align-items: stretch;
    }
    .header_sidebar {
        height: auto;
    }
}
@media screen and (max-width: 415px) {
    .header {
        justify-content: flex-end;
        padding: 20px 5%;
    }
    .header_logo {
        display: none;
    }
    .header_sidebar {
        border-left: none;
    }
}