.select {
    display: inline-block;
    width: 200px;
    position: relative;
}
.selected {
    height: 36px;
    line-height: 20px;
    padding: 8px;
    color: #3a4141;
    border-bottom: 1px solid;
    cursor: pointer;
}
.options {
    border-right: 1px solid #3a414182;
    border-left: 1px solid #3a414182;
    border-bottom: 1px solid #3a414182;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    overflow: hidden;
}
.option {
    height: 36px;
    line-height: 20px;
    padding: 8px;
    background-color: white;
    color: #3a414182;
    cursor: pointer;
    &:hover {
        background-color: #e82d86;
        color: white;
    }
}