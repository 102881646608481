.new_order {
    text-align: center;
    cursor: pointer;
    text-decoration: underline;
    padding: 10px 0;
    &:hover {
        color: #e82d86;
    }
    p:hover{
        color: #e82d86;
    }
}
.order_incoming{
    cursor: pointer;
    transition: all 100ms;
}
.order_incoming:hover{
    color: #e82d86;
}