@import 'src/styles/theme.scss';

.drop_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0.8rem;
    color: $text-color;
    background-color: $bg-primary-color;
    border-radius: 7px;
    font-weight: 700;
    cursor: pointer;
}