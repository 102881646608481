@import '../theme.scss';

.chatIcon {
    width: 70px;
    cursor: pointer;
}
.chatBox {
    display: flex;
    flex-direction: column;
    height: 600px;
    border: 1px solid #d4d4d4;
    border-radius: 10px;
    background-color: #ffffff;
    overflow: hidden;
}
.chatBox_header {
    height: 50px;
    position: relative;
    border-bottom: 1px solid #d4d4d4;
}
.chatBox_header-user {
    height: 100%;
}
.users_to_chat-overlay {
    position: absolute;
    z-index: 2;
    top: calc(100% + 5px);
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: all 300ms ease-out;
    padding: 0 0.5rem;
}
.users_to_chat {
    height: 280px;
    overflow-y: auto;
    border-radius: 10px;
    background-color: #d2d2d2;
    &::-webkit-scrollbar {
        width: 10px;
        border-radius: 100px;
        background-color: #ffb0cf;
    };
    &::-webkit-scrollbar-thumb {
        background-color: $pink-dark;
        border-radius: 100px;
    }
}
.no_chat {
    text-align: left;
    padding: 10px;
    height: 48px;
    line-height: 28px;
    cursor: pointer;
    background-color: $bg-three-color;
}
.user_to_chated {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    background-color: $bg-three-color;
    cursor: pointer;
    .user_to_chat-avatar {
        width: 2rem;
        height: 2rem;
        line-height: 2rem;
        text-align: center;
        background-color: #eaeaea;
        font-size: 0.9rem;
        font-weight: 700;
        color: $pink-dark;
        border-radius: 50%;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
}
.user_to_chat {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    &:hover {
        cursor: pointer;
        background-color: $bg-three-color;
    }
    .user_to_chat-avatar {
        width: 2.5rem;
        height: 2.5rem;
        line-height: 2.5rem;
        text-align: center;
        background-color: #eaeaea;
        font-size: 0.9rem;
        font-weight: 700;
        color: $pink-dark;
        border-radius: 50%;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
    .user_to_chat-name {
        text-align: left;
    }
}
.chatBox_content {
    height: 100%;
}
.message_content {
    height: 500px;
    overflow-y: auto;
    padding: 5px 0;
    &::-webkit-scrollbar {
        width: 10px;
        border-radius: 100px;
        background-color: #ffb0cf;
    };
    &::-webkit-scrollbar-thumb {
        background-color: $pink-dark;
        border-radius: 100px;
    }
}
.message_date {
    color: $text-color;
    font-size: 1.2rem;
    position: relative;
    height: 30px;
    margin-bottom: 10px;
    span {
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        &:nth-child(1) {
            width: 90%;
            height: 1px;
            background-color: $bg-primary-color;
            top: 15px;
        }
        &:nth-child(2) {
            padding: 0 10px;
            top: 7px;
            background-color: #ffffff;
        }
    }
}
.message_text {
    padding: 10px;
    border-radius: 10px;
}
.message_from {
    text-align: left;
    padding: 0 5px;
    margin-bottom: 10px;
    .message_text {
        display: inline-block;
        text-align: left;
        background-color: #e2e2e2;
    }
}
.message_to {
    text-align: right;
    padding: 0 5px;
    margin-bottom: 10px;
    .message_text {
        display: inline-block;
        text-align: right;
        color: white;
        background-color: #0084ff;
    }
}
.message_input {
    height: 50px;
    line-height: 50px;
    background-color: #f5f6f7;
    padding: 0 0.2rem;
    .input {
        background-color: #e2e2e2;
    }
}