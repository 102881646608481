$text-color: #3a4141;
$text-second-color: #3a414182;
$dark-color: #3a414185;
$pink-dark: #e82d86;
$pink-second: #f15693;
$bg-bold-color: #8c8c8c;
$bg-primary-color: #d0d0d0;
$bg-second-color: #d2d2d259;
$bg-three-color: #f5f6f7;
$shadow-color: #a7a7a7;
.edit-item{
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    flex-direction: column;
    
    .nav{
        // button{
        //     cursor: pointer;
        //     margin-right: 1%;
        //     border: none;
        //     border-radius: 10px;
        //     outline: none;
        //     padding: 1%;
        //     color: white;
        //     background-color: $pink-dark;
        // }
        // margin-bottom: 2%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1rem;
    }
    .store{
        display: flex;
        flex-direction: column;
        background-color: #dadada;
        padding-bottom: 5%;
        border-radius: 15px;
        .head{
            border-radius: 15px;
            background-color: #bebebe;
            color: black;
            padding: 1%;
            display: flex;
            justify-content: space-between;
            span{
                font-weight: bold;
                font-size: large;
            }
            .right{
                width: 20%;
                display: flex;
                span{
                    margin-right: 3%;
                    font-size: 16px;
                }
            }
        }
        .body{
            padding: 1%;
            display: flex;
            flex-wrap: wrap;
            .picture{
                width: 40%;
                display: flex;
                flex-direction: column;
                img{
                    width: 100%;
                    height: auto;
                    border: 2px solid rgb(134, 134, 134);
                    border-radius: 10px;
                    margin-bottom: 2%;
                }
                .enable{
                    padding: 1% 2% 1% 2%;
                    outline: none;
                    border: 1px solid darkgray;
                    border-radius: 15px;
                }
                .disable{
                    color: $pink-dark;
                    opacity: 1;
                    background-color: #dadada;
                    padding: 1% 2% 1% 2%;
                    outline: none;
                    border: none;
                    font-weight: bold;
                }
            }
            .infor{
                display: flex;
                flex-direction: column;
                padding-left: 2%;
                width: 60%;
                .form{
                    width: 100%;
                    display: flex;
                    margin-right: 3%;
                    margin-top: 3%;
                    align-items: baseline;
                    span{
                        font-weight: bold;
                        margin-right: 3%;
                    }
                    .enable{
                        padding: 1% 2% 1% 2%;
                        outline: none;
                        border: 1px solid darkgray;
                        border-radius: 15px;
                    }
                    .disable{
                        color: $pink-dark;
                        opacity: 1;
                        background-color: #dadada;
                        padding: 1% 2% 1% 2%;
                        outline: none;
                        border: none;
                        font-weight: bold;
                    }
                    select {
                        -webkit-appearance: none;
                        -moz-appearance: none;
                    }
                }
                .form-checkbox{
                    width: 40%;
                    display: flex;
                    margin-right: 3%;
                    margin-top: 3%;
                    span{
                        font-weight: bold;
                    }
                    .enable{
                        margin-left: 2%;
                        padding: 1% 2% 1% 2%;
                        outline: none;
                        border: 1px solid darkgray;
                        border-radius: 15px;
                        width: 100%;
                    }
                    .disable{
                        opacity: 1;
                        background-color: #dadada;
                        padding: 1% 2% 1% 2%;
                        outline: none;
                        border: none;
                        font-weight: bold;
                    }
                }
                .list-topping{
                    margin-top: 5%;
                    table{
                        border-collapse: collapse;
                        width: 100%;
                        background-color: white;
                        border: none;
                        thead{
                            border-bottom: 1px solid black;
                            background-color: rgb(245, 245, 245);
                            th{
                                padding-top: 1%;
                                padding-bottom: 1%;
                                margin: 0;
                                border: none;
                            }
                        }
                        tr{
                            transition: all 300ms;
                            border: none;
                            border-bottom: 1px solid rgb(71, 71, 71);
                            td{
                                padding-top: 1%;
                                padding-bottom: 1%;
                                border: none;
                                text-align: center;
                                img{
                                    height: 3rem;
                                    width: auto;
                                }
                                span{
                                    border: none;
                                    padding: 5px;
                                    border-radius: 15px;
                                    color: white;
                                    background-color: rgb(64, 173, 64);
                                }
                            }
                            .label{
                            }
                            .view-detail{
                                cursor: pointer;
                                color: rgb(75, 75, 255);
                            }
                            .view-detail:hover{
                                text-decoration: underline;
                                color: rgb(55, 55, 255);
                            }
                        }
                        tr:hover{
                            background-color: rgb(219, 219, 219);
                        }
                    }
                }
                button{
                    color: white;
                    background-color: $pink-dark;
                    width: 10rem;
                    padding: 1% 2% 1% 2%;
                    border: none;
                    border-radius: 20px;
                    outline: none;
                    margin-top: 20%;
                    margin-left: 20%;
                    
                }
                button:hover{
                    background-color: $pink-second;
                }
            }
        }

        
    }
}

@media screen and (max-width: 768px) {
    .edit-store{
        .nav{
            flex-direction: row;
            button{
            }
        }
        .store{
            .head{
                span{
                }
                .right{
                    span{
                    }
                }
            }
            .body{
                .picture{
                    img{
                    }
                    .enable{
                    }
                    .disable{
                    }
                }
                .infor{
                    .form{
                        span{
                        }
                        .enable{
                        }
                        .disable{
                        }
                    }
                    .form-checkbox{
                        span{
                        }
                        .enable{
                        }
                        .disable{
                        }
                    }
                    button{
                        
                    }
                    button:hover{
                    }
                }
            }
    
            
        }
    }
}