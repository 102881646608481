$text-color: #3a4141;
$text-second-color: #3a414182;
$dark-color: #3a414185;
$pink-dark: #e82d86;
$pink-second: #f15693;
$bg-bold-color: #8c8c8c;
$bg-primary-color: #d0d0d0;
$bg-second-color: #d2d2d259;
$bg-three-color: #f5f6f7;
$shadow-color: #a7a7a7;
.list-branch{
    display: flex;
    flex-direction: column;
    .nav{
        button{
            margin-left: 3%;
            cursor: pointer;
            margin-right: 1%;
            border: none;
            border-radius: 10px;
            outline: none;
            padding: 1%;
            color: white;
            background-color: $pink-dark;
        }
    }
    .content{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .branch{
            cursor: pointer;
            width: 30%;
            margin: 1%;
            background-color: rgb(245, 245, 245);
            height: 9rem;
            border-radius: 20px;
            border: 1px solid darkgrey;
            box-shadow: -2px 2px 4px 1px rgb(214, 214, 214);
            padding: 2%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            transition: all 200ms;
            .address{
                display: flex;
                flex-direction: column;
                .street{
                    font-weight: bold;
                    font-size: 20px;
                }
                .district{
                    text-align: right;
                    font-size: 14px;
                }
            }
            
            .time{
                display: flex;
                justify-content: space-between;
            }
        }
        .branch:hover{
            background-color: rgb(238, 238, 238);
            box-shadow: -2px 2px 4px 1px rgb(214, 214, 214);
        }
    }
    
}

@media screen and (max-width: 768px) {
    .list-branch{
        .nav{

        }
        .content{
            flex-direction: column;
            .branch{
                width: 90%;
                margin-left: 2%;
                height: 6rem;
                .address{
                    .street{
                    }
                    .district{
                    }
                }
                
                .time{
                }
            }
            .branch:hover{
            }
        }
    }
    
}