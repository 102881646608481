$text-color: #3a4141;
$text-second-color: #3a414182;
$dark-color: #3a414185;
$pink-dark: #e82d86;
$pink-second: #f15693;
$bg-bold-color: #8c8c8c;
$bg-primary-color: #d0d0d0;
$bg-second-color: #d2d2d259;
$bg-three-color: #f5f6f7;
$shadow-color: #a7a7a7;
.menu-item{
    display: flex;
    flex-direction: column;
    button{
        width: 20%;
        margin-left: 80%;
        margin-bottom: 2%;
        cursor: pointer;
        margin-right: 1%;
        border: none;
        border-radius: 10px;
        outline: none;
        padding: 1%;
        color: white;
        background-color: $pink-dark;
    }
    table{
        border-collapse: collapse;
        width: 100%;
        background-color: white;
        border: none;
        thead{
            border-bottom: 1px solid #ddd;
            background-color: rgb(245, 245, 245);
            th{
                padding-top: 2%;
                padding-bottom: 2%;
                margin: 0;
                border: none;
            }
        }
        tr{
            padding-top: 50%;
            transition: all 300ms;
            border: none;
            border-bottom: 1px solid rgb(71, 71, 71);
            td{
                padding-top: 1%;
                padding-bottom: 1%;
                border: none;
                text-align: center;
                img{
                    height: 3rem;
                    width: 3rem;
                    object-fit: cover;
                }
                span{
                    border: none;
                    padding: 5px;
                    border-radius: 15px;
                    color: white;
                    background-color: rgb(64, 173, 64);
                }
            }
            .label{
            }
            .view-detail{
                cursor: pointer;
                color: rgb(75, 75, 255);
            }
            .view-detail:hover{
                text-decoration: underline;
                color: rgb(55, 55, 255);
            }
        }
        tr:hover{
            background-color: rgb(219, 219, 219);
        }
        tr:nth-child(even){background-color: #f2f2f2;}
    }
}
@media screen and (max-width: 768px) {
    .menu-item{
        overflow-x: scroll;
        button{
            width: 40%;
            margin-left: 50%;
        }
        table{
            thead{
                th{
                }
            }
            tr{
                td{
                    img{
                    }
                    span{
                    }
                }
                .view-detail{
                }
                .view-detail:hover{
                }
            }
            tr:hover{
            }
        }
    }
}