$text-color: #3a4141;
$text-second-color: #3a414182;
$dark-color: #3a414185;
$pink-dark: #e82d86;
$pink-second: #f15693;
$bg-bold-color: #8c8c8c;
$bg-primary-color: #d0d0d0;
$bg-second-color: #d2d2d259;
$bg-three-color: #f5f6f7;
$shadow-color: #a7a7a7;
.detail-branch{
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    flex-direction: column;
    
    .nav{
        button{
            margin-right: 1%;
            border: none;
            border-radius: 10px;
            outline: none;
            padding: 1%;
            color: white;
            background-color: $pink-dark;
        }
        margin-bottom: 2%;
    }
    .branch{
        display: flex;
        flex-direction: column;
        background-color: #dadada;
        padding-bottom: 5%;
        border-radius: 15px;
        .head{
            border-radius: 15px;
            background-color: #bebebe;
            color: black;
            padding: 0.8rem 1rem;
            display: flex;
            justify-content: space-between;
            span{
                font-weight: bold;
                font-size: large;
            }
            .right{
                width: 20%;
                display: flex;
                span{
                    margin-right: 3%;
                    font-size: 16px;
                }
            }
        }
        .body{
            padding: 1%;
            display: flex;
            .left{
                display: flex;
                flex-direction: column;
                width: 45%;
                margin-right: 5%;
                .form{
                    padding: 2%;
                    border-radius: 10px;
                    background-color: darkgray;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    margin-right: 3%;
                    margin-top: 3%;
                    
                    span{
                        font-weight: bold;
                        width: 40%;
                    }
                    .value{
                        width: 60%;
                        color: $pink-dark;
                        opacity: 1;
                        padding: 1% 2% 1% 2%;
                        outline: none;
                        border: none;
                        font-weight: bold;
                    }
                }
            }
            .right{
                display: flex;
                flex-direction: column;
                width: 40%;
                .form{
                    padding: 2%;
                    border-radius: 10px;
                    background-color: darkgray;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    margin-right: 3%;
                    margin-top: 3%;
                    align-items: center;
                    span{
                        font-weight: bold;
                        width: 40%;
                    }
                    .value{
                        width: 60%;
                        color: $pink-dark;
                        padding: 1% 2% 1% 2%;
                        outline: none;
                        border: none;
                        font-weight: bold;
                    }
                }
                
            }
            
        }
    }
}

@media screen and (max-width: 768px) {
    .detail-branch{
        .nav{
            flex-direction: row;
            width: 100%;
            button{
                width: 40%;
            }
        }
        .branch{
            .head{
                span{
                }
                .right{
                    margin-left: 10% !important;
                    span{
                    }
                }
            }
            .body{
                flex-direction: column;
                width: 100% !important;
                .left{
                    width: 100%;
                }
                .right{
                    width: 100%;
                }
                .form{
                    flex-direction: row !important; 
                    width: 100% !important;
                    justify-content: space-between;
                    span{
                        width: 60%;
                    }
                    .enable{
                        width: 40% !important;
                    }
                    .disable{
                    }
                }
                .form-checkbox{
                    span{
                    }
                    .enable{
                    }
                    .disable{
                    }
                }
            }
    
            button{
                
            }
            button:hover{
            }
        }
    }
}

