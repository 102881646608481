.button {
    border: none;
    outline: none;
    padding: 0.5rem 1.5rem;
    border-radius: 100px;
    color: #ffffff;
    cursor: pointer;
    &:active {
        opacity: 0.7;
    }
}