$bg-content-color : #eaebee; 
$btn-pink-color : #e72670;
$text-pink-color : #e84b87;
$text-active: #221f20;
$text-dark: #394142;
$bg-input: #dddfe4;
.item{
    margin-top: 2%;
    padding-left: 10%;
    text-align: left;
    border: none;
    border-radius: 5px;
    line-height: 40px;
    background-color: $bg-content-color;
    font-weight: bold;
    opacity: 0.55;
    cursor: pointer;
    span{
    }
}
.item:hover{
    opacity: 0.75;
}
.active{
    opacity: 1;
    background-color: darkgray;
}

@media screen and (max-width: 768px) {
    .item{
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        border: none;
        padding-left: 1%;
        padding-right: 1%;
        transition: all 100ms;
        background-color: white;
    }
    .active{
        opacity: 1;
        border-bottom:2px solid $btn-pink-color;

    }
}