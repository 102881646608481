.badge{
    width: 100%;
    padding: 7px;
    border: none;
    border-radius: 15px;
    color: white;
    span{
        padding: 3% 10%;
    }
    
}