@import '../theme.scss';

.service_management {
    display: flex;
    justify-content: space-between;
}
.invoice_list-item {
    display: flex;
    padding: 1rem 0;
    background-color: $bg-primary-color;
    border-radius: 10px;
    font-weight: 700;
    color: #3a4141;
    cursor: pointer;
    margin-bottom: 1rem;
}
.service_management--type {
    width: calc(100% / 3 - 10px);
    h5 {
        text-align: center;
        background-color: $bg-bold-color;
        color: white;
        padding: 0.8rem 0;
        border-radius: 10px;
        margin-bottom: 0.3rem;
    }
    p {
        text-align: center;
        background-color: $bg-second-color;
        color: $text-second-color;
        font-weight: 700;
        padding: 0.5rem 0;
        border-radius: 10px;
        margin-bottom: 0.3rem;
    }
    &:first-child p {
        color: $pink-dark;
    }
}
.commissions {
    margin-top: 1rem;
}
.commissions-title {
    display: flex;
    border-radius: 10px;
    background-color: $bg-primary-color;
    p {
        width: 50%;
        padding: 2rem 0;
        text-align: center;
        font-size: 1.4rem;
        font-weight: 700;
        &:first-child {
            border-right: 1px solid;
        }
    }
}
.commissions-detail {
    display: flex;
    border-radius: 10px;
    background-color: $bg-second-color;
    .commissions-box {
        width: 50%;
        padding: 2rem 2rem 4rem;
        &:first-child {
            border-right: 1px solid;
        }
        p {
            border-radius: 10px;
            background-color: $bg-primary-color;
            text-align: center;
            height: 40px;
        }
    }
    input {
        width: 100%;
        height: 100%;
        background-color: $bg-primary-color;
        border-radius: 10px;
        text-align: center;
        color: $text-color;
    }
}
.mad_service {
    display: flex;
    margin-top: 1rem;
    .image {
        width: 50%;
        border-radius: 10px;
        background-color: $bg-second-color;
        p {
            padding: 1rem 0;
            text-align: center;
            color: $pink-dark;
            font-size: 1.4rem;
            font-weight: 700;
        }
        label {
            width: 100%;
            cursor: pointer;
            padding: 2rem 0;
            text-align: center;
            border-radius: 10px;
            background-color: $bg-primary-color;
            font-size: 1.4rem;
            font-weight: 700;
            color: $text-color;
            span {
                font-size: 1.4rem;
            }
        }
    }
    .amount {
        width: 50%;
        padding: 0 2rem;
        p {
            padding: 1rem 0;
            font-size: 1.4rem;
            font-weight: 700;
            color: $text-color;
        }
        .amount-type {
            border-radius: 10px;
            padding: 1.5rem 0;
            text-align: center;
            background-color: $bg-second-color;
            input {
                background-color: transparent;
                border-bottom: 1px solid $text-color;
            }
            span {
                font-size: 1.4rem;
            }
        }
    }
}