@import '../theme.scss';

.account_detail {
    padding-top: 0.3rem;
}
.account_sub {
    padding: 0.5rem 0.8rem;
    border-radius: 7px;
    font-weight: 700;
    background-color: $bg-second-color;
    color: $text-second-color;
    margin-bottom: 0.3rem;
    cursor: pointer;
    &:last-child {
        margin-bottom: 0;
    }
}
.account_list {
    background-color: $bg-second-color;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 1rem;
}
.account_list-item {
    display: flex;
    padding: 1rem 0;
    background-color: $bg-primary-color;
    border-radius: 10px;
    font-weight: 700;
    color: $text-color;
    cursor: pointer;
}
.account_list--title {
    padding: 1.2rem;
    background-color: $bg-primary-color;
    border-radius: 10px;
    font-size: 1.4rem;
    font-weight: bold;
    cursor: pointer;
}
.account_list-detail {
    padding: 5%;
}
.account_list--field {
    display: flex;
    align-items: center;
    margin-bottom: 0.8rem;
}
.time-close {
    margin-left: 3rem;
}
.account_list--key {
    font-size: 1.3rem;
    &:first-child {
        width: 200px;
        display: flex;
        justify-content: space-between;
        .account_list-name {
            font-size: 1.3rem;
            font-weight: 700;
        }
    }
    &:last-child {
        padding-left: 0.4rem;
        span {
            color: $text-color;
        }
        .account_list-hour {
            padding: 0.2rem 0.5rem;
            color: $pink-dark;
            border-radius: 10px;
            background-color: $bg-second-color;
            font-weight: 700;
            font-size: 1.3rem;
            margin: 0 0.5rem;
        }
    }
}
.account_list--button {
    text-align: right;
    margin-top: 3rem;
    padding-right: 4rem;
}
.account_list-time {
    display: flex;
}
.account_list-opening {
    white-space: nowrap;
}
.edit_title {
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    span:nth-child(1){
        font-weight: 700;
        font-size: 1.3rem;
        margin-right: 0.5rem;
    }
    span:nth-child(2) {
        width: 50%;
        height: 50px;
        line-height: 50px;
        background: $bg-second-color;
        color: $text-color;
        border-radius: 20px;
        text-align: center;
    }
}
.edit_form {
    padding: 4rem;
    background-color: #d2d2d259;
    border-radius: 10px;
}
.edit_form-field {
    display: flex;
    align-items: center;
    margin-bottom: 0.8rem;
    input {
        margin-left: 0.4rem;
        padding: 0.2rem 0;
        padding-left: 0.8rem;
        border-radius: 20px;
        width: 400px;
        background-color: $bg-second-color;
    }
}
.edit_form-key {
    width: 200px;
    display: flex;
    justify-content: space-between;
}
.edit_form-name {
    font-size: 1.3rem;
    font-weight: 700;
}
.edit_form-button {
    margin-top: 2rem;
    text-align: right;
    padding-right: 2rem;
}
.edit_form-radioGroup {
    display: flex;
    align-items: center;
    margin-left: 0.4rem;
    span:nth-child(1) {
        margin-right: 0.5rem;
    }
}
.user_list-title {
    padding: 0 0.5rem;
    width: 100%;
    border-right: 2px solid white;
    text-align: center;
    &:last-child {
        border-right: none;
    }
}
.driver_list-title {
    display: inline-block;
    width: 100%;
    border-right: 2px solid white;
    text-align: center;
    &:last-child {
        border-right: none;
    }
}
.driver_commission {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}
.driver_commission-input {
    display: flex;
}
.operator_list-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}
.operator_list-item {
    padding: 1rem 2rem;
    background-color: $bg-second-color;
    color: $text-color;
    border-radius: 60px;
    font-weight: 700;
    margin-bottom: 1rem;
}
.operator_list-text {
    display: inline-block;
    width: calc(100% / 3 - 2px);
    border-right: 2px solid $bg-primary-color;
    text-align: center;
    &:last-child {
        border-right: none;
    }
}
.admin_list-item {
    padding: 1rem 2rem;
    background-color: $bg-second-color;
    color: $text-color;
    border-radius: 60px;
    font-weight: 700;
    margin-bottom: 1rem;
}
.admin_list-text {
    display: inline-block;
    width: calc(100% / 2 - 2px);
    border-right: 2px solid $bg-primary-color;
    text-align: center;
    &:last-child {
        border-right: none;
    }
}

// responsive

@media screen and (max-width: 1367px) {
    .time-close {
        margin-left: 0;
    }
    .account_list-time {
        display: block;
    }
}
@media screen and (max-width: 1025px) {
    .account_list--field {
        width: 75%;
    }
    .account_list--keysField {
        width: 45%;
    }
    .account_list--keyField {
        font-size: 1.1rem;
    }
    .account_list--button {
        margin-top: 2rem;
    }
}
@media screen and (max-width: 769px) {
    .edit_form-field input {
        width: 300px;
    }
}
@media screen and (max-width: 415px) {
    .account_list--field {
        display: block;
    }
    .account_list--key:first-child {
        width: auto;
        display: block;
    }
    .edit_title {
        span:nth-child(1) {
            display: none;
        }
        span:nth-child(2){
            width: 100%;
        }
        button {
            display: none;
        }
    }
    .edit_form-field {
        display: block;
        input {
            width: 100%;
            margin-left: 0;
            margin-top: 0.4rem;
        }
        .edit_form-radioGroup {
            margin-left: 0;
            margin-top: 0.4rem;
        }
    }
    .edit_form-key {
        display: block;
        width: auto;
    }
}