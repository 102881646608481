@import 'src/styles/theme.scss';

.item {
    padding: 0.6rem 1.2rem;
    color: $text-second-color;
    background-color: $bg-second-color;
    border-radius: 10px;
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    cursor: pointer;
}
.item_active {
    background-color: $bg-primary-color;
    color: $text-color;
}