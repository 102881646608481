.alert {
    padding: 8px 16px;
    border-radius: 6px;
    position: fixed;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    transition: all 300ms ease-out;
    color: #ffffff;
    font-weight: 700;
}