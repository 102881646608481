$text-color: #3a4141;
$text-second-color: #3a414182;
$dark-color: #3a414185;
$pink-dark: #e82d86;
$pink-second: #f15693;
$bg-bold-color: #8c8c8c;
$bg-primary-color: #d0d0d0;
$bg-second-color: #d2d2d259;
$bg-three-color: #f5f6f7;
$shadow-color: #a7a7a7;

.filter-order {
    display: flex;
    padding: 24px;
    .filter-select {
        width: 60px;
    }
    .filter-text {
        margin: 0 16px;
    }
}
.list-order{
    padding-left: 24px;
    background-color: white;
    overflow-x: auto;
    overflow-y: hidden;
    table{
        min-width: 140%;
        border-collapse: collapse;
        background-color: white;
        margin-bottom: 1%;
        thead{
            background-color: rgb(245, 245, 245);
            tr{
                border: 1px solid #ddd;
                th{
                    padding-top: 16px;
                    padding-bottom: 16px;
                    margin: 0;
                    border: 1px solid #ddd;
                }
            }
        }
        tbody{
            th,tr{
                border: 1px solid #ddd;
                transition: all 300ms;
                td{
                    padding-top: 14px;
                    padding-bottom: 14px;
                    padding-left: 10px;
                    padding-right: 10px;
                    border: 1px solid #ddd;
                    text-align: center;
                    img{
                        height: 3rem;
                        width: auto;
                    }
                    span{
                        border: none;
                        padding: 5px;
                        border-radius: 15px;
                        color: white;
                        background-color: rgb(64, 173, 64);
                    }
                    // button{
                    //     margin-left: 0;
                    //     color: white;
                    //     background-color: $pink-dark;
                    //     width: 100%;
                    //     padding: 1% 2% 1% 2%;
                    //     border: none;
                    //     border-radius: 20px;
                    //     outline: none;
                        
                    // }
                    // button:hover{
                    //     background-color: $pink-second;
                    // }
                }
                .label{
                }
                .view-detail{
                    cursor: pointer;
                    color: rgb(75, 75, 255);
                }
                .view-detail:hover{
                    text-decoration: underline;
                    color: rgb(55, 55, 255);
                }
            }
            tr:hover{
                background-color: rgb(219, 219, 219);
            }
            tr:nth-child(even){background-color: #f2f2f2;}
        }
    }
        
}
/* width */
.list-order::-webkit-scrollbar {
    width: 10px;
    height: 7px;
}

/* Track */
.list-order::-webkit-scrollbar-track {
    margin-top: 1%;
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
}

/* Handle */
.list-order::-webkit-scrollbar-thumb {
    background: $pink-dark; 
    border-radius: 10px;
}

/* Handle on hover */
.list-order::-webkit-scrollbar-thumb:hover {
    background: $pink-second; 
}

@media screen and (max-width: 768px) {
    .list-order{
        overflow-x: scroll;
        table{
            min-width: 300%;
            thead{
                
                th{
                }
            }
            tr{
                td{
                    img{
                    }
                    span{
                    }
                }
                .view-detail{
                }
                .view-detail:hover{
                }
            }
            tr:hover{
            }
        }
    }
}