$text-color: #3a4141;
$text-second-color: #3a414182;
$dark-color: #3a414185;
$pink-dark: #e82d86;
$pink-second: #f15693;
$bg-bold-color: #8c8c8c;
$bg-primary-color: #d0d0d0;
$bg-second-color: #d2d2d259;
$bg-three-color: #f5f6f7;
$shadow-color: #a7a7a7;
.list-restaurant{
    padding-left: 2%;
    background-color: white;
    .nav{
        margin-bottom: 2%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        select{
            background-color: #e9e9e9;
            padding: 10px 15px;
            outline: none;
            border: none;
            border-radius: 18px;
            width: 40%;
            color: $pink-dark;
            letter-spacing: 2px;
        }
    }
    table{
        min-width: 100%;
        border-collapse: collapse;
        width: 100%;
        background-color: white;
        border: none;
        
        thead{
            background-color: rgb(247, 247, 247);
            tr{
                border-bottom: 1px solid #ddd;
                th{
                    padding-top: 2%;
                    padding-bottom: 2%;
                    margin: 0;
                    border: none;
                }
            }
            
        }
        tr{
            
            transition: all 300ms;
            border: none;
            border-bottom: 1px solid #ddd;
            td{
                padding-top: 0.5%;
                padding-bottom: 0.5%;
                border: none;
                text-align: center;
                img{
                    height: 3rem;
                    width: auto;
                }
                span{
                    border: none;
                    padding: 5px;
                    border-radius: 15px;
                    color: white;
                    background-color: rgb(64, 173, 64);
                }
                button{
                    margin-left: 0;
                    color: white;
                    background-color: $pink-dark;
                    width: 100%;
                    padding: 1% 2% 1% 2%;
                    border: none;
                    border-radius: 20px;
                    outline: none;
                    
                }
                button:hover{
                    background-color: $pink-second;
                }
            }
            .label{
            }
            .view-detail{
                cursor: pointer;
                color: rgb(75, 75, 255);
            }
            .view-detail:hover{
                text-decoration: underline;
                color: rgb(55, 55, 255);
            }
        }
        tr:hover{
            background-color: rgb(219, 219, 219);
        }
        tr:nth-child(even){background-color: #f2f2f2;}
    }
}
@media screen and (max-width: 768px) {
    .list-order{
        overflow-x: scroll;
        table{
            min-width: 300%;
            thead{
                
                th{
                }
            }
            tr{
                td{
                    img{
                    }
                    span{
                    }
                }
                .view-detail{
                }
                .view-detail:hover{
                }
            }
            tr:hover{
            }
        }
    }
}