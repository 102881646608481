$bg-content-color : #eaebee; 
$btn-pink-color : #e72670;
$text-pink-color : #e84b87;
$text-active: #221f20;
$text-dark: #394142;
$bg-input: #dddfe4;

.card{
    border-radius: 15px;
    background-color: $bg-content-color;
    margin: 1%;
    
    
    .card-header{
        border-radius: 15px;
        display: flex;
        padding-top: 1%;
        padding-bottom: 1%;
        vertical-align: middle;
        position: relative;
        padding-left: 2%;
        padding-right: 2%;
        background-color: #dadada; 
        p{
            font-weight: bold;
            cursor: pointer;
        }
        a,img{
            position: absolute;
            right: 3%;
            cursor: pointer;
        }

        a{
            color: $text-pink-color;
        }
        a:hover{
            color: #e72670;
            border-bottom: 1px solid  #e72670;
        }
    }
    .card-body{
        overflow: hidden;
        transition: all 400ms ease-out;
        
        .content{
            transition: all 2s;
            margin: 1%;
        }
    }
}