$text-color: #3a4141;
$text-second-color: #3a414182;
$dark-color: #3a414185;
$pink-dark: #e82d86;
$pink-second: #f15693;
$bg-bold-color: #8c8c8c;
$bg-primary-color: #d0d0d0;
$bg-second-color: #d2d2d259;
$bg-three-color: #f5f6f7;
$shadow-color: #a7a7a7;
.new-branch{
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    flex-direction: column;
    
    .nav{
        button{
            cursor: pointer;
            margin-right: 1%;
            border: none;
            border-radius: 10px;
            outline: none;
            padding: 1%;
            color: white;
            background-color: $pink-dark;
        }
        margin-bottom: 2%;
    }
    .branch{
        display: flex;
        flex-direction: column;
        background-color: #dadada;
        padding-bottom: 5%;
        border-radius: 15px;
        .head{
            border-radius: 15px;
            background-color: #bebebe;
            color: black;
            padding: 1%;
            display: flex;
            justify-content: space-between;
            span{
                font-weight: bold;
                font-size: large;
            }
            .right{
                width: 20%;
                display: flex;
                span{
                    margin-right: 3%;
                    font-size: 16px;
                }
            }
        }
        .body{
            padding: 1%;
            display: flex;
            flex-wrap: wrap;
            .form{
                width: 40%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-right: 3%;
                margin-top: 3%;
                span{
                    font-weight: bold;
                }
                .enable{
                    padding: 1% 2% 1% 2%;
                    outline: none;
                    border: 1px solid darkgray;
                    border-radius: 15px;
                }
                .disable{
                    color: $pink-dark;
                    opacity: 1;
                    background-color: #dadada;
                    padding: 1% 2% 1% 2%;
                    outline: none;
                    border: none;
                    font-weight: bold;
                }
            }
            .form-checkbox{
                width: 40%;
                display: flex;
                margin-right: 3%;
                margin-top: 3%;
                align-items: center;
                span{
                    font-weight: bold;
                }
                .enable{
                    margin-left: 2%;
                    padding: 1% 2% 1% 2%;
                    outline: none;
                    border: 1px solid darkgray;
                    border-radius: 15px;
                }
                .disable{
                    color: $pink-dark;
                    opacity: 1;
                    background-color: #dadada;
                    padding: 1% 2% 1% 2%;
                    outline: none;
                    border: none;
                    font-weight: bold;
                }
            }
        }
        .map {
            position: relative;
            width: 95%;
            height: 300px;
            margin: auto;
            border-radius: 10px;
            overflow: hidden;
            & > div {
                height: 100%;
            }
        }

        button{
            color: white;
            background-color: $pink-dark;
            width: 10rem;
            padding: 1% 2% 1% 2%;
            border: none;
            border-radius: 20px;
            outline: none;
            margin-top: 2%;
            margin-left: 20%;
            
        }
        button:hover{
            background-color: $pink-second;
        }
    }
}

@media screen and (max-width: 768px) {
    .new-branch{
        .nav{
            flex-direction: row;
            width: 100%;
            button{
                width: 40%;
            }
        }
        .branch{
            .head{
                .right{
                    margin-left: 10% !important;
                }
            }
            .body{
                flex-direction: column;
                width: 100% !important;
                .form{
                    flex-direction: column;
                    width: 100% !important;
                    .enable{
                        width: 100% !important;
                    }
                }
            }
        }
    }
}