@import '../theme.scss';

.transaction_detail {
    padding-top: 0.3rem;
}
.transaction_sub {
    padding: 0.5rem 0.8rem;
    border-radius: 7px;
    font-weight: 700;
    background-color: $bg-second-color;
    color: $text-second-color;
    margin-bottom: 0.3rem;
    cursor: pointer;
    &:last-child {
        margin-bottom: 0;
    }

}
.store_transaction {
    padding: 3rem;
    background-color: $bg-second-color;
    border-radius: 10px;
}
.store_transaction-field {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    position: relative;
    span {
        font-size: 1.2rem;
        font-weight: 700;
        color: $text-color;
    }
    input {
        width: 50%;
        border: none;
        outline: none;
        background-color: $bg-second-color;
        padding: 0.5rem;
        border-radius: 10px;
    }
    img {
        cursor: pointer;
        width: 24px;
        height: 24px;
        position: absolute;
        right: 7px;
        top: 6px;
    }
}
.store_transaction-pays {
    padding: 0.4rem;
    width: 50%;
    background-color: $bg-second-color;
    border-radius: 10px;
    p {
        border-radius: 10px;
        padding: 0.2rem 0;
        text-align: center;
        background-color: #ffffff;
        &:first-child {
            margin-bottom: 0.3rem;
        }
    }
}
.store_transaction-desc {
    width: 100%;
    resize: none;
    border: none;
    outline: none;
    padding: 0.5rem;
    margin: 1rem 0;
    border-radius: 10px;
    background-color: $bg-second-color;
    &::placeholder {
        font-size: 0.8rem;
    }
}
.transaction_order{
    display: flex;
    flex-direction: column;
    background-color: #dadada;
    padding-bottom: 5%;
    border-radius: 15px;
    .head{
        border-radius: 15px;
        background-color: #bebebe;
        color: black;
        padding: 1%;
        display: flex;
        justify-content: space-between;
        span{
            font-weight: bold;
            font-size: large;
        }
        .right{
            width: 20%;
            display: flex;
            span{
                margin-right: 3%;
                font-size: 16px;
            }
        }
    }
    .body{
        padding: 1%;
        display: flex;
        .left{
            display: flex;
            flex-direction: column;
            width: 45%;
            margin-right: 5%;
            .form{
                padding: 2%;
                border-radius: 10px;
                background-color: darkgray;
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-right: 3%;
                margin-top: 3%;
                
                span{
                    font-weight: bold;
                    width: 40%;
                }
                .value{
                    width: 60%;
                    color: $pink-dark;
                    opacity: 1;
                    padding: 1% 2% 1% 2%;
                    outline: none;
                    border: none;
                    font-weight: bold;
                }
            }
        }
        .right{
            display: flex;
            flex-direction: column;
            width: 40%;
            .form{
                padding: 2%;
                border-radius: 10px;
                background-color: darkgray;
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-right: 3%;
                margin-top: 3%;
                align-items: center;
                span{
                    font-weight: bold;
                    width: 40%;
                }
                .value{
                    width: 60%;
                    color: $pink-dark;
                    padding: 1% 2% 1% 2%;
                    outline: none;
                    border: none;
                    font-weight: bold;
                }
            }
            
        }
        
    }
}