@import 'src/styles/theme.scss';

.radio {
    display: flex;
    align-items: center;
    cursor: pointer;
    input[type='radio'] {
        display: none;
    }
}
.radio_check {
    display: inline-block;
    border-radius: 50%;
}
.radio_label {
    margin-left: 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    cursor: pointer;
    height: 15px;
}