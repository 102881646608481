.input {
    width: 100%;
    background-color: #d2d2d2;
    height: 40px;
    border-radius: 99px;
    padding: 0 20px;
}
.error {
    font-size: 14px;
    color: red;
    padding-left: 10px;
}