.back {
    margin-bottom: 1rem;
    svg {
        color: #e82d86;
        margin-right: 0.2rem;
        cursor: pointer;
    }
    span {
        color: #3a4141;
        cursor: pointer;
    }
}