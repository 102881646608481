@import '../theme.scss';

.management_title {
    text-align: center;
    font-size: 1.5rem;
    color: $text-color;
    margin-bottom: 2rem;
}
.managements_content {
    display: flex;
}
.management_list {
    width: 30%;
    padding-right: 3rem;
    padding-bottom: 10rem;
    border-right: 1px solid black;
}
.management_detail {
    width: 70%;
    padding-left: 1rem;
}

@import './AccountSettings.scss';
@import './AccountManagement.scss';
@import './ServiceManagement.scss';
@import './TransactionManagement.scss';
@import './PromotionManagement.scss';
@import './PaymentManagement.scss';
@import './Chat.scss';

// responsive
@media screen and (max-width: 769px) {
    // managements
    .managements_content {
        flex-direction: column;
    }
    .management_list {
        display: flex;
        width: 100%;
        padding: 0;
        border-right: none;
        border-bottom: 1px solid black;
        margin-bottom: 1rem;
    }
    .management_item {
        text-align: center;
        width: calc(100% / 5);
    }
    .management_detail {
        padding: 0;
        width: 100%;
    }
}
@media screen and (max-width: 415px) {
    .management_list {
        flex-direction: column;
    }
    .management_item {
        text-align: center;
        width: 100%;
    }
}