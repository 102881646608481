@import '../theme.scss';

.coupon_box {
    padding: 1rem;
    border-radius: 10px;
    background-color: $bg-second-color;
    margin-top: 1rem;
}
.promotion_coupon {
    display: flex;
    img {
        width: 250px;
        border-radius: 10px;
    }
    .promotion_coupon-infor {
        margin-left: 2rem;
        .promotion_coupon-name {
            font-size: 24px;
            text-transform: capitalize;
        }
        .promotion_coupon-field {
            margin: 5px 0;
        }
    }
}
.promotion_coupon-action {
    text-align: right;
    margin-top: 2rem;
}
// add
.promotion_coupon_add {
    display: flex;
    padding: 1rem;
    border-radius: 10px;
    background-color: $bg-second-color;
    .coupon_image {
        width: 250px;
        border-radius: 10px;
        cursor: pointer;
        img {
            width: 100%;
        }
    }
    .promotion_coupon-infor {
        width: calc(100% - 266px);
        margin-left: 1rem;
        .promotion_coupon-field {
            display: flex;
            align-items: center;
            margin-bottom: 0.5rem;
            strong {
                width: 120px;
            }
            .promotion_coupon-input {
                width: calc(100% - 120px);
            }
            .react-datepicker-wrapper {
                width: calc(100% - 120px);
            }
            .promotion_select {
                width: calc(100% - 120px);
            }
            .promotion_selected {
                border-bottom: none;
                background-color: #d2d2d2;
                border-radius: 99px;
                color: #000000;
                padding: 8px 20px;
            }
        }
    }
}