$text-color: #3a4141;
$text-second-color: #3a414182;
$dark-color: #3a414185;
$pink-dark: #e82d86;
$pink-second: #f15693;
$bg-bold-color: #8c8c8c;
$bg-primary-color: #d0d0d0;
$bg-second-color: #d2d2d259;
$bg-three-color: #f5f6f7;
$shadow-color: #a7a7a7;

.store-infor{
    display: flex;
    img{
        width: 40%;
        height: auto;
    }
    .infor{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .des{
            margin-left: 10%;
            display: flex;
            flex-direction: column;
            .name{
                margin-top: 2%;
                margin-bottom: 2%;
                font-weight: bold;
                font-size: larger;
            }
            .star{

            }
            .label{
                width: 20%;
                margin-top: 10%;
            }
            .hotline{
                margin-top: 5%;
                font-weight: 600;
            }
            .active{
                margin-top: 10%;
                width: max-content;
                padding: 7px;
                border: none;
                border-radius: 15px;
                color: white;
                background-color: rgb(54, 173, 54);
            }
        }
        button{
            cursor: pointer;
            outline: none;
            width: 10rem;
            margin-left: 60%;
            padding: 1% 2% 1% 2%;
            border-radius: 15px;
            border: none;
            color: white;
            background-color: $pink-dark;
        }
        button:hover{
            opacity: 0.85;
        }
    }
}

@media screen and (max-width: 768px) {
    .store-infor{
        flex-direction: column;
        img{
            width: 100%;
            height: auto;
        }
        .infor{
            display: flex;
            flex-direction: column;
            .des{
                .name{
                    margin-top: 2%;
                    margin-bottom: 2%;
                    font-weight: bold;
                    font-size: larger;
                }
                .star{
    
                }
                .label{
                    width: 20%;
                    margin-top: 10%;
                }
            }
            button{
                width: 7rem;
                margin-left: 30%;
                padding: 1% 2% 1% 2%;
                margin-top: 10%;
            }
        }
    }
}