.pagination{
    text-align: center;
    padding: 16px;
    .page{
        padding: 16px;
        color: rgb(37, 37, 37);
        cursor: pointer;
    }
    .page:hover{
        color: rgb(92, 92, 92);
    }
}