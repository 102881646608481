$text-color: #3a4141;
$text-second-color: #3a414182;
$dark-color: #3a414185;
$pink-dark: #e82d86;
$pink-second-color: #e82d86ba;
$bg-primary-color: #f5f6f7;
$bg-second-color: #eaecef;
$shadow-color: #a7a7a7;

.login_title {
    text-align: center;
    color: $text-color;
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
}
.login_form {
    display: flex;
    flex-direction: column;
    width: 40%;
    .fieldInput {
        margin-bottom: 1rem;
    }
}
.login_passwd {
    position: relative;
    margin-bottom: 1rem;
    .inputPass {
        padding-left: 50px;
        padding-right: 40px;
    }
    svg {
        position: absolute;
        color: $text-second-color;
        font-size: 1.4rem;
        &:first-child {
            top: 9px;
            left: 20px;
        }
        &:last-child {
            top: 12px;
            right: 10px;
            cursor: pointer;
        }
    }
}
.login_btn {
    width: 200px;
    height: 50px;
    margin-left: 20px;
    background-color: $pink-dark;
    color: white;
    border: none;
    outline: none;
    border-radius: 200px;
    cursor: pointer;
    &:active {
        background-color: $pink-second-color;
    }
}

// responsive
@media screen and (max-width: 768px) {
    .login_form {
        width: 50%;
    }
}
@media screen and (max-width: 414px) {
    .login_form {
        width: 80%;
    }
}

